import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  margin-left: 8px;
`;
const Icon = styled.i`
  color: #c5c8c9;
`;

interface Props {
  /** ソートされているか */
  readonly isSorted: boolean;
  /** ソートは昇順か降順か */
  readonly isSortedDesc?: boolean;
}

/**
 * ソートのアイコン
 */
export const TableSortIcon: React.FC<Props> = (props: Props) => {
  /**
   * ソートアイコンを取得
   */
  const getIcon = (): string => {
    if (!props.isSorted) return 'fa-sort';
    if (props.isSortedDesc) return 'fa-sort-down';
    return 'fa-sort-up';
  };

  const className = `fas ${getIcon()}`;

  return (
    <Wrapper className='table-component-sort-icon'>
      <Icon className={ className } />
    </Wrapper>
  );
};
